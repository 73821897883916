import React, { useState } from 'react';
import './qualification.scss';

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My Personal Journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? 'qualification__button button--flex qualification__active'
                : 'qualification__button button--flex'
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? 'qualification__button button--flex qualification__active'
                : 'qualification__button button--flex'
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? 'qualification__content qualification__content--active'
                : 'qualification__content'
            }
          >
            <div className="qualification__data">
              <div className="qualification__data--left">
                <h3 className="qualification__title">User Experience Design</h3>
                <span className="qualification__subtitle">
                  Birmingham City University - UK
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2023 - Present</i>
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  <a
                    href="https://www.credly.com/badges/343090c3-1e94-4171-965a-ba6ac869dbac/public_url"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Meta Full-Stack Software Engineer
                  </a>
                </h3>
                <span className="qualification__subtitle">
                  Meta (Coursera) - USA
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2023 - 2023</i>
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div className="qualification__data--left">
                <h3 className="qualification__title">
                  <a
                    href="https://credentials.edx.org/credentials/cbec0ae20f9b406d9f4ebf32699d30bf/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Product Management
                  </a>
                </h3>
                <span className="qualification__subtitle">
                  University of Maryland (UMD, USMx) - USA
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2022 - 2023</i>
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  <a
                    href="https://credentials.edx.org/credentials/30b999850c954f8385f60605c2937eb0/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Computer Science for Web Programming
                  </a>
                </h3>
                <span className="qualification__subtitle">HarvardX - USA</span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2020 - 2021</i>
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div className="qualification__data--left">
                <h3 className="qualification__title">
                  B.Tech. Computer Science
                </h3>
                <span className="qualification__subtitle">
                  LAUTECH - Nigeria
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2009 - 2015</i>
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? 'qualification__content qualification__content--active'
                : 'qualification__content'
            }
          >
            <div className="qualification__data">
              <div className="qualification__data--left">
                <h3 className="qualification__title">
                  Web Developer / Technical Specialist
                </h3>
                <span className="qualification__subtitle">
                  Invest Like Aysha - Zurich, Switzerland.
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2021 - Present</i>
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  Full-Stack Web Developer
                </h3>
                <span className="qualification__subtitle">
                  Smartecniqs Digital Solutions - Lagos, Nigeria.
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2019 - 2023</i>
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div className="qualification__data--left">
                <h3 className="qualification__title">
                  Top Level Freelance Web Developer
                </h3>
                <span className="qualification__subtitle">
                  Fiverr - Tel Aviv, Israel.
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2018 - 2020</i>
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  Web Developer / IT Systems Administrator
                </h3>
                <span className="qualification__subtitle">
                  Idashjobs Services - Lagos, Nigeria.
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2016 - 2018</i>
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div className="qualification__data--left">
                <h3 className="qualification__title">
                  Front-End Web Developer
                </h3>
                <span className="qualification__subtitle">
                  Koncept Converge - Yaba, Lagos.
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt">2015 - 2016</i>
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
