import Image1 from '../../assets/images/ramon-i.jpg';
import Image2 from '../../assets/images/flemming-r.jpg';
import Image3 from '../../assets/images/sherianne-w.jpg';

export const Data = [
  {
    id: 1,
    image: Image1,
    title: 'Ramon I.',
    description:
      'Sammy is a highly spirited Web Developer and Digital Strategist with huge experience. He is very creative and capable, with a fantastic eye for unique designs. His coding is great and his designs are unique and innovative. Sammy is always thoughtful and considerate, an excellent team player who can also work independently.',
  },
  {
    id: 2,
    image: Image2,
    title: 'Flemming R.',
    description:
      "Worked with Sammy and his team through out the entire process of building our ecommerce platform. He's been a fantastic partner in building up the website, designing the pages & setting up the ecommerce modules. He delivered a high quality work with a professional attitude, skillful communication, fast, reliable & very creative solutions.",
  },
  {
    id: 3,
    image: Image3,
    title: 'Sherianne W.',
    description:
      'In this technological world, it is VERY hard to find someone who is knowledgeable, a true expert at what they do and someone who goes above and beyond for you like you are the only client that matters. Sammy and his team was able to connect my business on the various platforms I use and make it all automated.',
  },
];
