import tailwind from '../../assets/images/fet1-tailwind.png';
// import gatsby from '../../assets/images/fet2-gatsby.png';
import shadcn from '../../assets/images/fet2-shadcn.png';
import sass from '../../assets/images/fet3-sass.png';
import figma from '../../assets/images/fet4-figma.png';
import redux from '../../assets/images/fet5-redux.png';
import reactquery from '../../assets/images/fet6-react-query.png';

export const frontendData = [
  {
    id: 'fed1',
    name: 'HTML',
    level: 'Intermediate',
    bar: '70%',
  },
  {
    id: 'fed2',
    name: 'CSS',
    level: 'Intermediate',
    bar: '70%',
  },
  {
    id: 'fed3',
    name: 'JavaScript',
    level: 'Intermediate',
    bar: '60%',
  },
  {
    id: 'fed4',
    name: 'React',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'fed5',
    name: 'NextJS',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'fed6',
    name: 'Bootstrap',
    level: 'Intermediate',
    bar: '60%',
  },
];

export const frontendOthers = [
  {
    id: 'fet1',
    tool: tailwind,
    name: 'tailwind css',
  },
  {
    id: 'fet2',
    tool: shadcn,
    name: 'shadcn ui',
  },
  {
    id: 'fet3',
    tool: sass,
    name: 'sass',
  },
  {
    id: 'fet4',
    tool: figma,
    name: 'figma',
  },
  {
    id: 'fet5',
    tool: redux,
    name: 'react redux',
  },
  {
    id: 'fet6',
    tool: reactquery,
    name: 'react query',
  },
];
