import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { backendData, backendOthers } from './backendData';

const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend Development</h3>

      <div className="skills__group">
        {backendData.map(({ id, name, level, bar }) => {
          return (
            <div className="skills__data" key={id}>
              <i className="bx bx-badge-check"></i>

              <div className="skills__info">
                <div className="skills__title-bar">
                  <div className="skills__title-name">
                    <h3 className="skills__name">{name}</h3>
                    <span className="skills__number">{bar}</span>
                  </div>

                  <div className="skills__bar">
                    <span
                      className="skills__percentage"
                      style={{ width: bar }}
                    ></span>
                  </div>
                </div>

                <span className="skills__level">{level}</span>
              </div>
            </div>
          );
        })}
      </div>

      <div className="skills__other">
        <div className="skills__other--title">
          <h3>Other Tools</h3>
        </div>
        <div className="skills__other--tools">
          {backendOthers.map(({ id, tool, name }) => {
            return (
              <>
                <img
                  key={id}
                  src={tool}
                  alt={name}
                  className="tool"
                  data-tooltip-id={id}
                />
                <ReactTooltip
                  id={id}
                  place="top"
                  content={name}
                  className="tooltip"
                />
              </>
            );
          })}
        </div>
        <div className="skills__others--list"></div>
      </div>
    </div>
  );
};

export default Backend;
