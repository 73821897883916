import typescript from '../../assets/images/bet1-typescript.png';
import graphql from '../../assets/images/bet2-graphql.png';
import postman from '../../assets/images/bet3-postman.png';
import aws from '../../assets/images/bet4-aws.png';
import docker from '../../assets/images/bet5-docker.png';
import git from '../../assets/images/bet6-git.png';

export const backendData = [
  {
    id: 'bed1',
    name: 'Python',
    level: 'Intermediate',
    bar: '60%',
  },
  {
    id: 'bed2',
    name: 'Django',
    level: 'Intermediate',
    bar: '60%',
  },
  {
    id: 'bed3',
    name: 'Node.js',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'bed4',
    name: 'Express',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'bed5',
    name: 'MongoDB',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'bed6',
    name: 'PostgreSQL',
    level: 'Basic',
    bar: '50%',
  },
];

export const backendOthers = [
  {
    id: 'bet1',
    tool: typescript,
    name: 'typescript',
  },
  {
    id: 'bet2',
    tool: graphql,
    name: 'graphql',
  },
  {
    id: 'bet3',
    tool: postman,
    name: 'postman',
  },
  {
    id: 'bet4',
    tool: aws,
    name: 'aws',
  },
  {
    id: 'bet5',
    tool: docker,
    name: 'docker',
  },
  {
    id: 'bet6',
    tool: git,
    name: 'git',
  },
];
