import React from 'react';
import Form from './Form';
import './contact.scss';

const Contact = () => {
  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Get in touch</h2>
      <span className="section__subtitle">Contact Me</span>

      <div className="contact__container container">
        <div className="contact__content">
          <h3 className="contact__title">Talk to me</h3>

          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__card--icon"></i>

              <h3 className="contact__card--title">Email</h3>
              <span className="contact__card--data">sammyleths@gmail.com</span>

              <a
                href="mailto:sammyleths@gmail.com"
                className="contact__button"
                target="_blank"
                rel="noreferrer"
              >
                Write me
                <i className="bx bx-right-arrow-alt contact__button--icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-skype contact__card--icon"></i>

              <h3 className="contact__card--title">Skype</h3>
              <span className="contact__card--data">sammyleths</span>

              <a
                href="https://join.skype.com/invite/acEkR70J18GF"
                className="contact__button"
                target="_blank"
                rel="noreferrer"
              >
                Write me
                <i className="bx bx-right-arrow-alt contact__button--icon"></i>
              </a>
            </div>

            <div className="contact__card">
              <i className="bx bxl-linkedin contact__card--icon"></i>

              <h3 className="contact__card--title">Linkedin</h3>
              <span className="contact__card--data">eyiowuawi</span>

              <a
                href="https://www.linkedin.com/in/eyiowuawi/"
                className="contact__button"
                target="_blank"
                rel="noreferrer"
              >
                Write me
                <i className="bx bx-right-arrow-alt contact__button--icon"></i>
              </a>
            </div>

            <div className="contact__card ">
              <i className="bx bxl-messenger contact__card--icon"></i>

              <h3 className="contact__card--title">Messenger</h3>
              <span className="contact__card--data">eyiowuawi1</span>

              <a
                href="https://m.me/eyiowuawi1"
                className="contact__button"
                target="_blank"
                rel="noreferrer"
              >
                Write me
                <i className="bx bx-right-arrow-alt contact__button--icon"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="contact__content" id="startproject">
          <h3 className="contact__title">Let's discuss your project</h3>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default Contact;
