import './app.scss';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Qualification from './components/qualification/Qualification';
import Portfolio from './components/portfolio/Portfolio';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import Theme from './utils/theme/Theme';
import Certification from './components/certification/Certification';
import { useThemeContext } from './context/theme-context';
import { Slide } from 'react-awesome-reveal';
import { useEffect, useState } from 'react';
import { useMultiLayerPageReveal } from 'react-multilayer-page-reveal';
import LoadingOverlay from 'react-loading-overlay-ts';
import FavLoader from './utils/loaders/FavLoader';

const mainElement = document.getElementById('root');

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { reveal } = useMultiLayerPageReveal();

  const { themeState } = useThemeContext();
  const rootClass = `main ${themeState.primary} ${themeState.background}`;
  mainElement.className = rootClass;

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      const overlayTimeout = setTimeout(() => {
        reveal();
        setTimeout(() => {
          setIsLoading(() => false);
        }, 500);
      }, 2000);

      return () => clearTimeout(overlayTimeout);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [reveal]);

  return isLoading ? (
    <LoadingOverlay
      active={isLoading}
      spinner={<FavLoader className="myspinner" />}
      styles={{
        wrapper: {
          width: isLoading ? '100vw' : '100%',
          height: isLoading ? '100vh' : '100%',
          overflowY: isLoading ? 'hidden' : 'scroll',
          position: 'fixed',
        },
        overlay: (base) => ({
          ...base,
          background: '#FFFFFF',
        }),
      }}
    />
  ) : (
    <main>
      <Header />
      <Home />
      <Slide direction="up" cascade="true" triggerOnce="false">
        <About />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Skills />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Services />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Qualification />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Certification />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Portfolio />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Testimonials />
      </Slide>
      <Slide direction="up" cascade="true" triggerOnce="false">
        <Contact />
      </Slide>
      <Footer />
      <ScrollUp />
      <Theme />
    </main>
  );
}

export default App;
