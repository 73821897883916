import React from 'react';
//import Fav from '../../assets/svg/sl-fav.svg';
import './fav-loader.scss';

const FavLoader = () => {
  return (
    <div className="fav-loader">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 346.1 147.16"
      >
        <path
          d="M101.62,282.45,77,254.43l24.67-28.14h31.62l-24.68,28.14,24.68,28Z"
          transform="translate(-76.95 -176.42)"
          fill="#fff"
        />
        <path
          d="M154.83,292.16q-10.16-7.53-10.92-21.53h26.86c.25,3.17,1.2,5.48,2.82,6.94a10.25,10.25,0,0,0,12,.45,5.76,5.76,0,0,0,2.25-4.82,6.81,6.81,0,0,0-3.73-6.17,64.66,64.66,0,0,0-12.08-4.88,109,109,0,0,1-14.33-5.72,27.56,27.56,0,0,1-9.51-8.1q-4.05-5.33-4.05-14a25.46,25.46,0,0,1,4.37-15A27,27,0,0,1,160.61,210a45.13,45.13,0,0,1,17.48-3.22q15.81,0,25.26,7.39t10.09,20.76H186.06c-.09-2.74-.92-4.8-2.51-6.17a9,9,0,0,0-6.1-2.06,6.55,6.55,0,0,0-4.63,1.67,6.18,6.18,0,0,0-1.8,4.76,5.86,5.86,0,0,0,2,4.43,18,18,0,0,0,4.94,3.22q3,1.35,8.74,3.4A106.57,106.57,0,0,1,200.9,250a29,29,0,0,1,9.64,8.1q4.05,5.2,4.05,13.17a26.64,26.64,0,0,1-4.05,14.52A27.79,27.79,0,0,1,198.85,296a40.89,40.89,0,0,1-18.06,3.72Q165,299.67,154.83,292.16Z"
          transform="translate(-76.95 -176.42)"
          fill="#fff"
        />
        <path
          d="M251.48,279.5h28.14v19.27H226.29v-90.6h25.19Z"
          transform="translate(-76.95 -176.42)"
          fill="#fff"
        />
        <path
          d="M363.68,176.42,329.36,323.58H305.07l34.31-147.16Z"
          transform="translate(-76.95 -176.42)"
          fill="#fff"
        />
        <path
          d="M366.89,226.29h31.49l24.67,28.14-24.67,28H366.89l24.67-28Z"
          transform="translate(-76.95 -176.42)"
          fill="#fff"
        />
      </svg>
      <div className="border-animation border-animation--border-1"></div>
      <div className="border-animation border-animation--border-2"></div>
    </div>
  );
};

export default FavLoader;
