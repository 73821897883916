import React from 'react';
import EngrCert from './EngrCert';
import MngrCert from './MngrCert';
import './certification.scss';

const Certifications = () => {
  return (
    <section className="certification section" id="certification">
      <h2 className="section__title">Certifications</h2>
      <span className="section__subtitle">My Professional Credentials</span>

      <div className="certification__container container">
        <EngrCert />
        <MngrCert />
      </div>
      <div className="certification__view-all">
        <a
          href="https://www.linkedin.com/in/eyiowuawi/details/certifications/"
          className="button button--flex"
          target="_blank"
          rel="noreferrer"
        >
          View All Certs
          <svg
            className="button__icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M18,10.82a1,1,0,0,0-1,1V19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8A1,1,0,0,1,5,7h7.18a1,1,0,0,0,0-2H5A3,3,0,0,0,2,8V19a3,3,0,0,0,3,3H16a3,3,0,0,0,3-3V11.82A1,1,0,0,0,18,10.82Zm3.92-8.2a1,1,0,0,0-.54-.54A1,1,0,0,0,21,2H15a1,1,0,0,0,0,2h3.59L8.29,14.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L20,5.41V9a1,1,0,0,0,2,0V3A1,1,0,0,0,21.92,2.62Z"
            />
          </svg>
        </a>
      </div>
    </section>
  );
};

export default Certifications;
