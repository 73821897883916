import React from 'react';
import ReactDOM from 'react-dom';
import './alert.scss';

const Alert = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div className={`alert__popup ${props.class}`}>
          <i class={props.icon}></i>
          <p>{props.message}</p>
          <i class="bx bx-x alert--close" onClick={props.onConfirm}></i>
        </div>,
        document.getElementById('root')
      )}
    </React.Fragment>
  );
};

export default Alert;
