import React from 'react';
import { productData } from './productData';

const Product = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Technical Product Management</h3>

      <div className="skills__group">
        {productData.map(({ id, name, level, bar }) => {
          return (
            <div className="skills__data" key={id}>
              <i className="bx bx-badge-check"></i>

              <div className="skills__info">
                <div className="skills__title-bar">
                  <div className="skills__title-name">
                    <h3 className="skills__name">{name}</h3>
                    <span className="skills__number">{bar}</span>
                  </div>

                  <div className="skills__bar">
                    <span
                      className="skills__percentage"
                      style={{ width: bar }}
                    ></span>
                  </div>
                </div>

                <span className="skills__level">{level}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Product;
