import React, { useState } from 'react';
import ServiceModal from './ServiceModal';

const Service = ({ service, index }) => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  let serviceTitle = service.title.split(' ');

  return (
    <div className="services__contentbox">
      <div>
        <i className={service.icon + ` services__icon`}></i>
        <h3 className="services__title">
          {serviceTitle.map((title, index) => {
            return (
              <span key={index}>
                {title}
                <br />
              </span>
            );
          })}
        </h3>
      </div>

      <span className="services__button" onClick={() => toggleTab(index)}>
        View More
        <i className="uil uil-arrow-right services__button--icon"></i>
      </span>

      <ServiceModal
        service={service}
        toggleState={toggleState}
        toggleTab={toggleTab}
      />
    </div>
  );
};

export default Service;
