import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ModalProvider } from './context/modal-context';
import { ThemeProvider } from './context/theme-context';
import { MultiLayerPageRevealProvider } from 'react-multilayer-page-reveal';

function getThreeRandomColors(arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}

const layerColors = [
  '#262626',
  '#570F39',
  '#574D0F',
  '#570F0F',
  '#0F0F57',
  '#1B570F',
];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MultiLayerPageRevealProvider
    preset="triple-woosh"
    direction="left"
    layerColors={getThreeRandomColors(layerColors, 3)}
  >
    <ThemeProvider>
      <ModalProvider>
        <App />
      </ModalProvider>
    </ThemeProvider>
  </MultiLayerPageRevealProvider>
);
