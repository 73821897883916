import React from 'react';
import { engrCertData } from './engrCertData';

const EngrCert = () => {
  return (
    <div className="certification__content">
      <h3 className="certification__category">
        <i className="uil uil-brackets-curly certification__icon"></i>
        Software Engineering
      </h3>
      {engrCertData.map(({ id, name, issuer, year, link }) => {
        return (
          <div className="certification__item" key={id}>
            <h3 className="certification__title">{name}</h3>
            <p className="certification__issuer">{issuer}</p>
            <p className="certification__date">
              <i className="uil uil-calendar-alt"> {year}</i>
            </p>
            <a
              href={link}
              className="certification__button"
              target="_blank"
              rel="noreferrer"
            >
              View Credential
              <i className="uil uil-arrow-right certification__button--icon"></i>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default EngrCert;
