import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const ProjectItems = ({ projects, galleryID }) => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + galleryID,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, [galleryID]);

  const portfolioLinks = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div className="portfolio__container container" id={galleryID}>
      {projects.map((item, index) => {
        return (
          <div className="portfolio__card" key={item.id}>
            <div className="portfolio__box">
              <img src={item.image} alt="" className="portfolio__img" />
              <div className="portfolio--info">
                <div className="caption">
                  <a
                    href={item.image}
                    data-pswp-width={item.width}
                    data-pswp-height={item.height}
                    key={galleryID + '-' + index}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="uil uil-search"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="portfolio__tech">
              {item.tech.map((stack) => {
                return (
                  <span
                    key={item.id + stack}
                    className="portfolio__tech--stack"
                  >
                    {stack}
                  </span>
                );
              })}
            </div>
            <h3 className="portfolio__title">{item.title}</h3>
            <div className="portfolio__links">
              <span
                href={item.demo}
                className="portfolio__button"
                onClick={() => {
                  portfolioLinks(item.demo);
                }}
              >
                Demo
                <i className="bx bx-right-arrow-alt portfolio__button--icon"></i>
              </span>
              <span
                href={item.github}
                className="portfolio__button"
                onClick={() => {
                  portfolioLinks(item.github);
                }}
              >
                Github
                <i className="bx bx-right-arrow-alt portfolio__button--icon"></i>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectItems;
