import React from 'react';
import ReactDOM from 'react-dom';

const ModalOverlay = ({ service, toggleTab, toggleState, index }) => {
  return (
    <div
      className={
        toggleState === index
          ? 'services__modal active-modal'
          : 'services__modal'
      }
    >
      <div className="services__modal--content">
        <i
          className="uil uil-times services__modal--close"
          onClick={() => toggleTab(0)}
        ></i>

        <h3 className="services__modal--title">{service.title}</h3>
        <p className="services__modal--description">{service.description}</p>

        <ul className="services__modal--services grid">
          {service.serviceList.map((task, index) => {
            return (
              <li className="services__modal--service" key={index}>
                <i className="uil uil-check-circle services__modal--icon"></i>
                <p className="services__modal--info">{task}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const ServiceModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          service={props.service}
          toggleTab={props.toggleTab}
          toggleState={props.toggleState}
        />,
        document.getElementById('root')
      )}
    </React.Fragment>
  );
};

export default ServiceModal;
