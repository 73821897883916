export const productData = [
  {
    id: 'pd1',
    name: 'Product Roadmap Dev',
    level: 'Basic',
    bar: '40%',
  },
  {
    id: 'pd2',
    name: 'Agile Methodology',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'pd3',
    name: 'Product Research',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'pd4',
    name: 'Prototyping',
    level: 'Intermediate',
    bar: '60%',
  },
  {
    id: 'pd5',
    name: 'Data Analysis',
    level: 'Basic',
    bar: '50%',
  },
  {
    id: 'pd6',
    name: 'Product-Market Fit',
    level: 'Basic',
    bar: '40%',
  },
];
