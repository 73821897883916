import Work1 from '../../assets/images/proj1-number-prediction.png';
import Work2 from '../../assets/images/proj2-dicey-roll.png';
import Work3 from '../../assets/images/proj3-microbank.png';
import Work4 from '../../assets/images/proj4-microbank-website.png';
import Work5 from '../../assets/images/proj5-xcisetrackr.png';
import Work6 from '../../assets/images/proj6-recipecloud.png';
import Work7 from '../../assets/images/proj7-unicorn-agency.png';
import Work8 from '../../assets/images/proj8-makeova.png';
import Work9 from '../../assets/images/proj9-smartpoet.png';
import Work10 from '../../assets/images/proj10-condo-rentals.png';
import Work11 from '../../assets/images/proj11-witty-ai.png';
import Work12 from '../../assets/images/proj12-ai-ally.png';
import Work13 from '../../assets/images/proj13-samis-ecom-admin.png';
import Work14 from '../../assets/images/proj14-samis-ecom-store.png';

export const projectsData = [
  {
    id: 'proj14',
    image: Work14,
    title: 'Samis Ecom Store',
    category: ['frontend', 'backend', 'fullstack', 'product'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://samis-ecom-store.vercel.app/',
    github: 'https://github.com/SammyLeths/samis-ecom-store',
    description:
      'AI Ally Software As A Service (SaaS) is an AI tool that can be used to create AI characters which you can have Q&A conversations with.',
    tech: [
      'react',
      'nextjs',
      'typescript',
      'tailwindcss',
      'mysql',
      'prisma',
      'clerkauth',
      'radixui',
      'stripe',
    ],
  },
  {
    id: 'proj13',
    image: Work13,
    title: 'Samis Ecom Admin',
    category: ['frontend', 'backend', 'fullstack', 'product'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://samis-ecom-admin.vercel.app/',
    github: 'https://github.com/SammyLeths/samis-ecom-admin',
    description:
      'AI Ally Software As A Service (SaaS) is an AI tool that can be used to create AI characters which you can have Q&A conversations with.',
    tech: [
      'react',
      'nextjs',
      'typescript',
      'tailwindcss',
      'mysql',
      'prisma',
      'clerkauth',
      'radixui',
      'stripe',
    ],
  },
  {
    id: 'proj12',
    image: Work12,
    title: 'AI Ally',
    category: ['frontend', 'backend', 'fullstack', 'product'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://ai-ally.vercel.app/',
    github: 'https://github.com/SammyLeths/ai-ally',
    description:
      'AI Ally Software As A Service (SaaS) is an AI tool that can be used to create AI characters which you can have Q&A conversations with.',
    tech: [
      'react',
      'nextjs',
      'typescript',
      'tailwindcss',
      'mysql',
      'prisma',
      'clerkauth',
      'openai',
      'pinecone',
      'radixui',
      'stripe',
    ],
  },
  {
    id: 'proj11',
    image: Work11,
    title: 'Witty AI SaaS',
    category: ['frontend', 'backend', 'fullstack', 'product'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://witty-ai-saas.vercel.app/',
    github: 'https://github.com/SammyLeths/witty-ai-saas',
    description:
      'Witty AI Software As A Service is an AI tool that can be used to create content 10x faster. This tool allows automatic generation of contents such as Images, Videos, Music and Code. It can also generate meaningful conversational responses via chat.',
    tech: [
      'react',
      'nextjs',
      'typescript',
      'tailwindcss',
      'mysql',
      'prisma',
      'clerkauth',
      'openai',
      'replicateai',
      'stripe',
    ],
  },
  {
    id: 'proj10',
    image: Work10,
    title: 'Condo Rentals',
    category: ['frontend', 'backend', 'fullstack', 'product'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://condo-rentals.vercel.app/',
    github: 'https://github.com/SammyLeths/condo-rentals',
    description:
      'Condo Rentals is a real estate listing marketplace for short let and long term homestay experiences inspired by Airbnb.',
    tech: [
      'react',
      'nextjs',
      'typescript',
      'tailwindcss',
      'mongodb',
      'prisma',
      'nextauth',
      'auth0',
      'leaflet',
      'cloudinary',
    ],
  },
  {
    id: 'proj9',
    image: Work9,
    title: 'Smartpoet',
    category: ['frontend', 'backend', 'fullstack', 'product'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://smartpoet.herokuapp.com/',
    github: 'https://github.com/SammyLeths/smartpoet-os',
    description:
      'The project involves development of an online publishing platform for poets where they can publish poems.',
    tech: [
      'python',
      'django',
      'javascript',
      'bootstrap5',
      'postgresql',
      'disqus',
      'cloudinary',
      'djangoallauth',
    ],
  },
  {
    id: 'proj8',
    image: Work8,
    title: 'Makeova',
    category: ['frontend'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://makeova.netlify.app/',
    github: 'https://github.com/SammyLeths/makeova',
    description:
      'A modern responsive HTML5 landing page template suitable for use by home renovation agencies or contractors.',
    tech: [
      'html5',
      'sass',
      'javascript',
      'bootstrap5',
      'css3',
      'postcss',
      'glightbox',
      'cvs',
      'npm',
    ],
  },
  {
    id: 'proj7',
    image: Work7,
    title: 'Unicorn Agency',
    category: ['frontend'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://unicorn-agency.netlify.app/',
    github: 'https://github.com/SammyLeths/unicorn-agency',
    description:
      'A modern responsive HTML5 landing page template suitable for use by digital agencies.',
    tech: [
      'html5',
      'sass',
      'css3',
      'javascript',
      'bootstrap5',
      'postcss',
      'glightbox',
      'npm',
    ],
  },
  {
    id: 'proj6',
    image: Work6,
    title: 'Recipecloud',
    category: ['frontend', 'backend', 'fullstack'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://recipecloud.netlify.app/',
    github: 'https://github.com/SammyLeths/recipecloud',
    description:
      'A web application that queries recipe data from a cloud API. The application is able to get and display different food recipe data from numerous sources.',
    tech: [
      'javascript',
      'sass',
      'css3',
      'html5',
      'parceljs',
      'corejs',
      'fracty',
      'restapi',
    ],
  },
  {
    id: 'proj5',
    image: Work5,
    title: 'XciseTrackr',
    category: ['frontend', 'backend', 'fullstack'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://xcisetrackr.netlify.app/',
    github: 'https://github.com/SammyLeths/xcisetrackr',
    description:
      'A simple web application that allows users track their physical exercise such as cycling and running based on the location where these workouts were performed.',
    tech: [
      'javascript',
      'html5',
      'sass',
      'css3',
      'postcss',
      'leafletjs',
      'npm',
    ],
  },
  {
    id: 'proj4',
    image: Work4,
    title: 'Microbank Website',
    category: ['frontend'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://microbank-website.netlify.app/',
    github: 'https://github.com/SammyLeths/microbank-website',
    description:
      'The front facing informational website for Microbank web app.',
    tech: ['html5', 'css3', 'javascript', 'bootstrap5', 'swiperjs', 'git'],
  },
  {
    id: 'proj3',
    image: Work3,
    title: 'Microbank',
    category: ['frontend'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://microbank.netlify.app/',
    github: 'https://github.com/SammyLeths/microbank',
    description:
      'A simple web app that exemplifies micro features of a modern banking application.',
    tech: ['html5', 'css3', 'javascript', 'localstorage', 'git'],
  },
  {
    id: 'proj2',
    image: Work2,
    title: 'Dicey Roll Game',
    category: ['frontend'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://dicey-roll-game.netlify.app/',
    github: 'https://github.com/SammyLeths/dice-roll',
    description: 'A simple and interesting game you can play with a friend.',
    tech: ['javascript', 'git', 'html5', 'css3', 'dicegame'],
  },
  {
    id: 'proj1',
    image: Work1,
    title: 'Number Prediction',
    category: ['frontend'],
    galleryId: 'myProjects',
    width: 1280,
    height: 840,
    demo: 'https://number-prediction.netlify.app/',
    github: 'https://github.com/SammyLeths/number-prediction',
    description: 'Basic number guessing game',
    tech: ['html5', 'css3', 'javascript', 'game'],
  },
];

export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'frontend',
  },
  {
    name: 'backend',
  },
  {
    name: 'fullstack',
  },
  {
    name: 'product',
  },
];
