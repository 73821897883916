export const mngrCertData = [
  {
    id: 1,
    name: 'Modern Product Leadership',
    issuer: 'University of Maryland (UMD, USMx)',
    year: '2023',
    link: 'https://courses.edx.org/certificates/a78bcda3da694192a2379a630148c8e3',
  },
  {
    id: 2,
    name: 'Data Science and Agile Systems Engineering',
    issuer: 'University of Maryland (UMD, USMx)',
    year: '2023',
    link: 'https://courses.edx.org/certificates/66787eaf0ce844e5962d93dd19246130',
  },
  {
    id: 3,
    name: 'Product Design, Prototyping, and Testing',
    issuer: 'University of Maryland (UMD, USMx)',
    year: '2023',
    link: 'https://courses.edx.org/certificates/dda4576351fc4f58822c8d869e167750',
  },
  {
    id: 4,
    name: 'Achieving Product-Market Fit',
    issuer: 'University of Maryland (UMD, USMx)',
    year: '2023',
    link: 'https://courses.edx.org/certificates/17722a309f1140c2987178ca55840a24',
  },
  {
    id: 5,
    name: 'Product Management Fundamentals',
    issuer: 'University of Maryland (UMD, USMx)',
    year: '2022',
    link: 'https://courses.edx.org/certificates/431f58ddf0c34ca09435f29f095c6d25',
  },
];
