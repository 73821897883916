import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Alert from './Alert';
import Spinner from '../../utils/loaders/Spinner';

import useInput from '../../utils/hooks/use-input';

const Form = (props) => {
  const form = useRef();
  const [formResponse, setFormResponse] = useState();
  const [spinner, setSpinner] = useState();

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetNameInput,
  } = useInput((value) => value.trim() !== '' && !Boolean(value.match(/\d/)));

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmailInput,
  } = useInput(
    (value) => value.trim() !== '' && value.includes('@') && value.includes('.')
  );

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: messageInputHasError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetMessageInput,
  } = useInput((value) => value.trim() !== '');

  let nameInputErrMsg;
  let emailInputErrMsg;
  let messageInputErrMsg;

  // Name Input

  if (enteredName.trim() === '') {
    nameInputErrMsg = 'Name must not be empty';
  } else if (Boolean(enteredName.match(/\d/))) {
    nameInputErrMsg = 'Name must not include numbers. Use only letters';
  }

  // Email Input

  if (enteredEmail.trim() === '') {
    emailInputErrMsg = 'Email must not be empty';
  } else if (!enteredEmail.includes('@')) {
    emailInputErrMsg = `Please include an '@' in the email address`;
  } else if (!enteredEmail.includes('.')) {
    emailInputErrMsg = `Please enter a valid part following the '@' symbol`;
  }

  // Message/Project Input

  if (enteredMessage.trim() === '') {
    messageInputErrMsg = 'Project details must not be empty';
  }

  // Overall Form Validity

  let formIsValid = false;

  const activateSpinner = (status) => {
    setSpinner(status);
  };

  if (enteredNameIsValid && enteredEmailIsValid && enteredMessageIsValid) {
    formIsValid = true;
  }

  // Form Submit Listener

  const submitHandler = (e) => {
    e.preventDefault();

    nameBlurHandler();
    emailBlurHandler();
    messageBlurHandler();

    if (!formIsValid) {
      setFormResponse({
        message:
          'There are some errors in the form. Please correct them and retry',
        class: 'alert--danger show--alert',
        icon: 'bx bxs-error',
      });
      setTimeout(() => {
        responseHandler();
      }, '7000');
      return;
    } else {
      activateSpinner(true);
      emailjs
        .sendForm(
          'service_ggmvbmo',
          'pv2_template',
          form.current,
          'WglzI6XnEYcxTofSX'
        )
        .then(
          (result) => {
            //console.log(result.text);
            setFormResponse({
              message: 'Your message has been sent successfully',
              class: 'alert--success show--alert',
              icon: 'bx bxs-check-circle',
            });
            setTimeout(() => {
              responseHandler();
            }, '7000');
            activateSpinner(false);
          },
          (error) => {
            //console.log(error.text);
            setFormResponse({
              message:
                'An error occured while submitting your message, please try again',
              class: 'alert--danger show--alert',
              icon: 'bx bxs-error',
            });
            setTimeout(() => {
              responseHandler();
            }, '7000');
            activateSpinner(false);
          }
        );
    }

    resetNameInput();
    resetEmailInput();
    resetMessageInput();
  };

  const responseHandler = () => {
    setFormResponse(null);
  };

  const nameInputClasses = nameInputHasError
    ? 'contact__form--div invalid'
    : 'contact__form--div';

  const emailInputClasses = emailInputHasError
    ? 'contact__form--div invalid'
    : 'contact__form--div';

  const messageInputClasses = messageInputHasError
    ? 'contact__form--div contact__form--area invalid'
    : 'contact__form--div contact__form--area';

  return (
    <div>
      {formResponse && (
        <Alert
          message={formResponse.message}
          class={formResponse.class}
          icon={formResponse.icon}
          onConfirm={responseHandler}
        />
      )}
      <form className="contact__form" ref={form} onSubmit={submitHandler}>
        <div className={nameInputClasses}>
          <label htmlFor="name" className="contact__form--tag">
            Name
          </label>
          <input
            type="text"
            name="name"
            className="contact__form--input"
            placeholder="Insert your name"
            value={enteredName}
            onBlur={nameBlurHandler}
            onChange={nameChangeHandler}
          />
          {nameInputHasError && (
            <p className="field--error">{nameInputErrMsg}</p>
          )}
        </div>
        <div className={emailInputClasses}>
          <label htmlFor="email" className="contact__form--tag">
            Email
          </label>
          <input
            type="email"
            name="email"
            className="contact__form--input"
            placeholder="Insert your email"
            value={enteredEmail}
            onBlur={emailBlurHandler}
            onChange={emailChangeHandler}
          />
          {emailInputHasError && (
            <p className="field--error">{emailInputErrMsg}</p>
          )}
        </div>
        <div className={messageInputClasses}>
          <label htmlFor="project" className="contact__form--tag">
            Project
          </label>
          <textarea
            className="contact__form--input"
            name="project"
            cols="30"
            rows="5"
            placeholder="Insert your project details"
            value={enteredMessage}
            onBlur={messageBlurHandler}
            onChange={messageChangeHandler}
          ></textarea>
          {messageInputHasError && (
            <p className="field--error">{messageInputErrMsg}</p>
          )}
        </div>
        <div className="contact__form--div contact__form--footer">
          <button href="#contact" className="button button--flex">
            Send Message
            <svg
              className="button__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                fill="#fff"
              ></path>
              <path
                d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                fill="#fff"
              ></path>
            </svg>
          </button>
          {spinner && <Spinner />}
        </div>
      </form>
    </div>
  );
};

export default Form;
