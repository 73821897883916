import React from 'react';
import './footer.scss';

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  const logoText = '< SammyLeths />';

  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">{logoText}</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>
          <li>
            <a href="#portfolio" className="footer__link">
              Sandbox
            </a>
          </li>
          <li>
            <a href="#testimonials" className="footer__link">
              Reviews
            </a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/eyiowuawi/"
            className="footer__social--link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-linkedin"></i>
          </a>
          <a
            href="https://www.instagram.com/sammy_leths/"
            className="footer__social--link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-instagram"></i>
          </a>
          <a
            href="https://github.com/SammyLeths"
            className="footer__social--link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-github"></i>
          </a>
        </div>

        <span className="footer__copy">
          &#169; {year} SammyLeths, All rights reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
