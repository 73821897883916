import React, { useState } from 'react';
//import ThemeModal from './ThemeModal';
import { useModalContext } from '../../context/modal-context';
import './header.scss';

const Header = () => {
  const { showModalHandler } = useModalContext();

  /* ========= Change Header Background ========= */
  window.addEventListener('scroll', function () {
    const header = this.document.querySelector('.header');
    if (this.scrollY >= 80) header.classList.add('scroll-header');
    else header.classList.remove('scroll-header');
  });

  /* ========= Toggle Menu ========= */
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState('#home');

  const logoText = '< SammyLeths />';

  return (
    <header className="header">
      <div className="container">
        <nav className="nav">
          <a href="index.html" className="nav__logo">
            {logoText}
          </a>

          <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
            <ul className="nav__list grid">
              <li className="nav__item">
                <a
                  href="#home"
                  onClick={() => setActiveNav('#home')}
                  className={
                    activeNav === '#home'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-estate nav__icon"></i>
                  Home
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  onClick={() => setActiveNav('#about')}
                  className={
                    activeNav === '#about'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-user nav__icon"></i>
                  About
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#skills"
                  onClick={() => setActiveNav('#skills')}
                  className={
                    activeNav === '#skills'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-file-alt nav__icon"></i>
                  Skills
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#services"
                  onClick={() => setActiveNav('#services')}
                  className={
                    activeNav === '#services'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-briefcase-alt nav__icon"></i>
                  Services
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#qualification"
                  onClick={() => setActiveNav('#qualification')}
                  className={
                    activeNav === '#qualification'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-graduation-cap nav__icon"></i>
                  Journey
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#certification"
                  onClick={() => setActiveNav('#certification')}
                  className={
                    activeNav === '#certification'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-medal nav__icon"></i>
                  Certification
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#portfolio"
                  onClick={() => setActiveNav('#portfolio')}
                  className={
                    activeNav === '#portfolio'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-layer-group nav__icon"></i>
                  Sandbox
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#testimonials"
                  onClick={() => setActiveNav('#testimonials')}
                  className={
                    activeNav === '#testimonials'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-star nav__icon"></i>
                  Reviews
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#contact"
                  onClick={() => setActiveNav('#contact')}
                  className={
                    activeNav === '#contact'
                      ? 'nav__link active-link'
                      : 'nav__link'
                  }
                >
                  <i className="uil uil-message nav__icon"></i>
                  Contact
                </a>
              </li>
            </ul>

            <i
              className="uil uil-times nav__close"
              onClick={() => showMenu(!Toggle)}
            ></i>
          </div>

          <div className="theme-wrapper" onClick={showModalHandler}>
            <div className="nav__theme">
              <i className="uil uil-palette nav__theme--icon"></i>
            </div>
            <div className="border-animation border-animation--border-1"></div>
            <div className="border-animation border-animation--border-2"></div>
          </div>

          {/* <ThemeModal /> */}

          <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
            <i className="uil uil-apps"></i>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
