import React from 'react';
import './portfolio.scss';
import Projects from './Projects';

const Portfolio = () => {
  return (
    <section className="portfolio section" id="portfolio">
      <h2 className="section__title">Sandbox</h2>
      <span className="section__subtitle">Recent Projects</span>
      <Projects />
    </section>
  );
};

export default Portfolio;
