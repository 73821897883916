export const engrCertData = [
  {
    id: 1,
    name: 'Meta Front-End Developer',
    issuer: 'Meta (Coursera)',
    year: '2023',
    link: 'https://www.credly.com/badges/0f5ee509-7f48-48ec-88f2-5c71ae767779/public_url',
  },
  {
    id: 2,
    name: 'Meta Back-End Developer',
    issuer: 'Meta (Coursera)',
    year: '2023',
    link: 'https://www.credly.com/badges/6a46f172-9f77-4fbc-b562-5b23a6c4c5aa/public_url',
  },
  {
    id: 3,
    name: 'AWS Certified Cloud Practitioner',
    issuer: 'Amazon Web Services (AWS)',
    year: '2022',
    link: 'https://www.credly.com/badges/76034ca6-38e8-4e8b-922b-fc9d8b7497aa/public_url',
  },
  {
    id: 4,
    name: 'Developing Cloud Applications with Node.js and React',
    issuer: 'International Business Machines (IBM)',
    year: '2022',
    link: 'https://www.credly.com/badges/2d8f0d46-1a83-47f8-bb95-d0aacac99556/public_url',
  },
  {
    id: 5,
    name: 'Web Programming with Python and JavaScript',
    issuer: 'Harvard University (HarvardX)',
    year: '2021',
    link: 'https://courses.edx.org/certificates/ec697f969be545ca876687e0ee06bd24',
  },
];
