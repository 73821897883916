import Modal from '../modal/Modal';
import { primaryColors, backgroundColors } from './data';
import PrimaryColor from './PrimaryColor';
import BackgroundColor from './BackgroundColor';
import { useModalContext } from '../../context/modal-context';
import './theme.scss';

const Theme = () => {
  const { closeModalHandler } = useModalContext();

  return (
    <Modal className="theme__modal">
      <i
        className="uil uil-times services__modal--close"
        onClick={closeModalHandler}
      ></i>
      <h3>Customize Theme</h3>
      <small>
        Change website theme according to your preference. Choose any color that
        suits you
      </small>
      <div className="theme__primary--wrapper">
        <h5>Theme Colors</h5>
        <div className="theme__primary--colors">
          {primaryColors.map((pColor) => (
            <PrimaryColor key={pColor.className} className={pColor.className} />
          ))}
        </div>
      </div>
      <div className="theme__background--wrapper">
        <h5>Light / Dark Mode</h5>
        <div className="theme__background--colors">
          {backgroundColors.map((bColor) => (
            <BackgroundColor
              key={bColor.className}
              className={bColor.className}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default Theme;
