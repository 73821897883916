import React, { useState, useEffect } from 'react';
import { projectsData, projectsNav } from './Data';
import ProjectItems from './ProjectItems';

const Projects = () => {
  const [item, setItem] = useState({ name: 'all' });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (item.name === 'all') {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        //return project.category.toLowerCase() === item.name;
        return project.category.includes(item.name);
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  return (
    <div>
      <div className="portfolio__filters">
        {projectsNav.map((item, index) => {
          return (
            <span
              onClick={(e) => {
                handleClick(e, index);
              }}
              className={`${
                active === index ? 'active-portfolio' : ''
              } portfolio__item`}
              key={index}
            >
              {item.name}
            </span>
          );
        })}
      </div>

      <ProjectItems projects={projects} galleryID="myPortfolio" />
    </div>
  );
};

export default Projects;
