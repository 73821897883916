export const serviceData = [
  {
    id: 'serv1',
    icon: 'uil uil-brackets-curly',
    title: 'Frontend Development',
    description:
      'I can help bring your next big idea to live with a digital product that incorporates seamless user interface design, clean code and uncompromising accessibility. As a frontend developer I can:',
    serviceList: [
      'Create design concepts and mockups of products.',
      'Use HTML, CSS and JavaScript to bring concepts to life',
      'Implement responsive design across multiple devices',
      'Optimize product user experience.',
      'Create UX element interactions.',
    ],
  },
  {
    id: 'serv2',
    icon: 'uil uil-database',
    title: 'Backend Development',
    description:
      'Need a performant, robust and scalable backend for your web frontend applications that controls data exchange between user clients and the database seamlessly? As a backend developer I can:',
    serviceList: [
      'Implement a resilient and tolerant backend architecture for your product.',
      'Create, maintain and consume REST API.',
      'Setup connection with external systems. ',
      'Configure infrastructure to handle execution of resource-intensive processes.',
      'Design an adaptive and connected database structure',
    ],
  },
  {
    id: 'serv3',
    icon: 'uil uil-cloud-database-tree',
    title: 'Cloud DevOps',
    description:
      'For a digital product to thrive it needs to reside in the right environment. An environment that can cater for efficient development operations, balance service reliability and speedy delivery. As a budding cloud devops engineer I can:',
    serviceList: [
      'Implement continuous integration (CI).',
      'Manage cloud services',
      'Maintain serverless architecture',
      'Perform infrastructure automation',
      'Deploy products to the cloud',
    ],
  },
  {
    id: 'serv4',
    icon: 'uil uil-web-grid',
    title: 'Product Management',
    description:
      'Have a product management team large enough for specialization? I can join your team as a budding technical product manager to enhance the technical part of your product startegy and deliver:',
    serviceList: [
      'Synchronization with development, engineering, infrastructure and networking teams.',
      'Comprehension of customer needs and translation into requirements.',
      'Product promotion while serving as subject matter expert to the technical/developer community.',
      'Analysis of performance indicators and experiments evaluation.',
      'Assessing of emerging products and make recommendations on which new technologies to invest in or leverage.',
    ],
  },
];
